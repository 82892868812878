// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-managing-a-field-team-is-not-an-easy-job-js": () => import("./../../../src/pages/managing-a-field-team-is-not-an-easy-job.js" /* webpackChunkName: "component---src-pages-managing-a-field-team-is-not-an-easy-job-js" */),
  "component---src-pages-meet-agile-reporting-decide-without-guessing-js": () => import("./../../../src/pages/meet-agile-reporting–decide-without-guessing.js" /* webpackChunkName: "component---src-pages-meet-agile-reporting-decide-without-guessing-js" */),
  "component---src-pages-new-customer-needs-the-new-face-of-the-store-js": () => import("./../../../src/pages/new-customer-needs-the-new-face-of-the-store.js" /* webpackChunkName: "component---src-pages-new-customer-needs-the-new-face-of-the-store-js" */),
  "component---src-pages-nowe-potrzeby-klientow-nowe-oblicze-sklepow-js": () => import("./../../../src/pages/nowe-potrzeby-klientow-nowe-oblicze-sklepow.js" /* webpackChunkName: "component---src-pages-nowe-potrzeby-klientow-nowe-oblicze-sklepow-js" */),
  "component---src-pages-raportuj-zwinnie-decyduj-bez-zgadywania-js": () => import("./../../../src/pages/raportuj-zwinnie-decyduj-bez-zgadywania.js" /* webpackChunkName: "component---src-pages-raportuj-zwinnie-decyduj-bez-zgadywania-js" */),
  "component---src-pages-zarzadzanie-rozproszona-struktura-nie-nalezy-do-latwych-zadan-js": () => import("./../../../src/pages/zarzadzanie-rozproszona-struktura-nie-nalezy-do-latwych-zadan.js" /* webpackChunkName: "component---src-pages-zarzadzanie-rozproszona-struktura-nie-nalezy-do-latwych-zadan-js" */)
}

