/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';

import './src/styles/index.scss';
import Store from './src/store/Store';

export const wrapRootElement = ({ element }) => <Store>{element}</Store>;
