import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Context } from './context';

const initialState = {
  language: 'PL',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PL':
      return {
        ...state,
        language: 'PL',
      };
    case 'SET_ENG':
      return {
        ...state,
        language: 'ENG',
      };
    default:
      return state;
  }
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

Store.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Store;
